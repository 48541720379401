<template>
  <b-container fluid>
    <!--    v-if="getNewUserCallData&&getNewUserCallData.data"-->
    <b-modal id="changeStatus" size="lg" title="Change Status" hide-footer>
      <b-row class="d-flex flex-wrap" >
        <b-col v-for="(status, key) in allStatus" :key="key" class="col-3 text-black text-center mb-3 cursor-pointer" >
           <span :class="[{ 'shadow bg-primary text-white' : isSelected(key)},'d-inline-block w-100 p-1 custom-rounded  primary-color ']" @click="(selectedStatus = key) ; print(status.id)">
            <i  :class="[{ 'las la-check-square' : isSelected(key)}]"></i> {{status.title}}
          </span>
          <span  class="d-block w-100 h-10 bg-white"></span>
          <span :style="[{ backgroundColor:status.color }]" class="d-block w-100 h-10">
          </span>
        </b-col>
      </b-row>
      <div class="mt-2">
        <b-form-textarea
            id="textarea-rows"
            placeholder="Comments..."
            rows="2"
            v-model="comment"
        ></b-form-textarea>
      </div>
      <div class="d-flex justify-content-center mt-2">
        <b-button variant="primary" class="w-50" @click="saveEditStatus">Save</b-button>
      </div>
    </b-modal>
    <b-modal id="statusHistory" size="lg" title="History" show hide-footer>
      <statusHistory :allHistory="history" :historyId="historyId"/>
    </b-modal>
    <b-modal id="addStatusAndComments"  size="lg" v-if="getNewUserCallData&&getNewUserCallData.data"  centered hide-header-close no-close-on-esc  no-close-on-backdrop :title="titleCall" hide-footer>
      <div class="d-flex px-3 row flex-wrap">
        <b-col  v-for="(status, key) in allStatus" :key="key" class="col-3 text-black text-center mb-3 cursor-pointer" >
          <span :class="[{ 'shadow bg-primary text-white' : isSelected(key)},'d-inline-block w-100 p-1 custom-rounded  primary-color ']" @click="(selectedStatus = key) ; print(status.id)">
            <i  :class="[{ 'las la-check-square' : isSelected(key)}]"></i> {{status.title}}
          </span>
          <span  class="d-block w-100 h-10 bg-white"></span>
          <span :style="[{ backgroundColor:status.color }]" class="d-block w-100 h-10">
          </span>
        </b-col>
      </div>
      <div class="mt-2">
        <b-form-textarea
            id="textarea-rows"
            placeholder="Comments..."
            rows="2"
            v-model="comment"
        ></b-form-textarea>
      </div>
      <div class="d-flex justify-content-center mt-2">
        <b-button variant="danger" class="w-25 mr-1" @click="endCall()"> End Call </b-button>
        <b-button variant="primary" class="w-25 ml-1" @click="saveStatus(getNewUserCallData.data.id)"> Save </b-button>
      </div>
    </b-modal>
    <b-row>
      <b-col lg="12">
        <iq-card v-if="getNewUserCallData.message">
          <b-alert show variant="warning"> {{getNewUserCallData.message}}</b-alert>
        </iq-card>
        <iq-card class="mb-2" v-if="getNewUserCallData&&getNewUserCallData.data">
          <div class="row py-2">
            <div class="col-md-3 text-center align-items-center">
              <p class="text-black font-weight-bold m-0 mb-1">Name</p>
              <p class="m-0">{{ getNewUserCallData.data.name }}</p>
            </div>
            <div class="col-md-3 text-center">
              <p class="text-black font-weight-bold m-0 mb-1">Phone</p>
              <p class="m-0">{{ getNewUserCallData.data.phone }}</p>
            </div>
            <div class="col-md-3 text-center">
              <p class="text-black font-weight-bold m-0 mb-1">Project</p>
              <p class="m-0">{{ getNewUserCallData.data.project.en_title }}</p>
            </div>
            <div class="col-md-3 text-center">
              <p class="text-black font-weight-bold m-0 mb-1">Membership</p>
              <p class="m-0">{{ getNewUserCallData.data.membership_status ? getNewUserCallData.data.data.membership_status : 'ــــــ' }}</p>
            </div>
          </div>
        </iq-card>
        <iq-card>
          <div class="row py-2 pl-4">
            <div class="mr-2">
              <b-button variant="primary" @click="getNewUser('new')">
                Get New
              </b-button>
            </div>
            <div class="mr-2">
              <b-button variant="primary" @click="getNewUser('challenge')">
                Get Chalenge
              </b-button>
            </div>
            <div class="mr-2">
              <b-button variant="primary"  @click="getNewUser('old')">
                Get Old
              </b-button>
            </div>
          </div>
        </iq-card>
        <iq-card  class="p-2">
          <b-button variant='danger' :class="{ notAllowed: selectedRow.length > 0 ? false : true }" :disabled ="selectedRow.length > 0 ? false : true"  @click="selectedRow = []" ><i class="las la-check-square"></i> remove Select</b-button>
          <b-button variant='light' :class="[{ notAllowed: selectedRow.length > 0 ? false : true },'ml-2']" :disabled ="selectedRow.length > 0 ? false : true"  @click="openChangeStatus" ><i class="las la-sync-alt"></i> change Status</b-button>
        </iq-card>
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('dashboard2.leads')}}</h4>
          </template>
          <template v-slot:headerAction>
          </template>
          <template v-slot:body>
            <div v-if="loadTable" class="text-center">
              <b-spinner small type="grow"></b-spinner>
              Loading...
            </div>
            <b-table v-else striped responsive  @row-selected="onRowSelected" :fields="columns" :items="getUserCallData" class="mb-0 table-borderless" :select-mode="selectMode" ref="selectableTable" sort-icon-left primary-key="name"  id="table-transition-example" :tbody-transition-props="transProps">
              <template #cell(selected)="data">
                <!--                <template v-if="rowSelected">
                                  <span aria-hidden="true">&check;</span>
                                  <span class="sr-only">Selected</span>
                                </template>
                                <template v-else>
                                  <span aria-hidden="true">&nbsp;</span>
                                  <span class="sr-only">Not selected</span>
                                </template>-->
                <b-form-group>
                  <b-form-checkbox
                      v-model="selectedRow"
                      :value="data.item.id"
                  >
                  </b-form-checkbox>
                </b-form-group>
              </template>
              <template v-slot:cell(project)="data1">
                {{data1.item.project.en_title}}
              </template>
              <template v-slot:cell(call_status)="data2">
                <span class="p-1 iq-border-radius-5" v-if="data2.item.call_status && data2.item.call_status.color" :style="{ backgroundColor: data2.item.call_status.color }">{{data2.item.call_status.title}}</span>
                <spam v-else>
                  <span class="p-1 iq-border-radius-5" style="background-color: #d6cdd3">Pending</span>
                </spam>
              </template>
              <template v-slot:cell(phone)="data1">
                <p class="holdSelection" v-if="data1.item.id==showPhone">{{data1.item.phone}}</p>
                <b-button v-else variant=" iq-bg-success mr-1 mb-1" size="sm" :id="data1.item.id" @click="showPhone = data1.item.id" ><i class="las la-mobile"></i>Show Phone</b-button>
              </template>
              <template v-slot:cell(call_action)="data1">
                <i class="las la-phone-volume text-success font-size-18 cursor-pointer" @click="openChangeStatus(); selectedRow.push(data1.item.id)"></i>
              </template>
              <template v-slot:cell(comment)="data2">
                {{data2.item.comment==null? 'ـــــ' :  data2.item.comment}}
              </template>
              <template v-slot:cell(actions)="data1">
                <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="showHistory(data1.item.id)" ><i class="las la-history"></i> Show History</b-button>
              </template>
            </b-table>
            <b-pagination v-model="currentPage"
                          class="mt-3"
                          :total-rows="total"
                          align="right"
                          :per-page="per_page"
                          aria-controls="my-table"
                          @input="changeContent"
            ></b-pagination>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="4">
        <iq-card>
          <template v-slot:body>
            <ul class="d-flex justify-content-around cursor-pointer">
              <li><span>Daily</span></li>
              <li><span>Weekly</span></li>
              <li><span>Monthly</span></li>
            </ul>
            <p class="mb-0">{{ $t('dashboard2.totalLikeComment')}}</p>
            <div class="m-0 p-0 d-flex flex-wrap align-items-center justify-content-between">
              <div class="col-md-6">
                <div class="d-flex align-items-center  mt-4">
                  <div class="iq-scale-border mr-3 like-block"></div>
                  <div class="iq-scale-content">
                    <h6>2.864</h6>
                    <p class="mb-0">Leads</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="d-flex align-items-center  mt-4">
                  <div class="iq-scale-border mr-3 comment-block"></div>
                  <div class="iq-scale-content">
                    <h6>624</h6>
                    <p class="mb-0">Facebook</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="d-flex align-items-center mt-4">
                  <div class="iq-scale-border mr-3 share-block"></div>
                  <div class="iq-scale-content">
                    <h6>1.75</h6>
                    <p class="mb-0">Instagram</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="d-flex align-items-center mt-4">
                  <div class="iq-scale-border mr-3 repost-block"></div>
                  <div class="iq-scale-content">
                    <h6>345</h6>
                    <p class="mb-0">Complaints</p>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../config/pluginInit'
import { mapGetters } from 'vuex'
const addLeadModel = () => import('../../components/leads/leadsModal')
const statusHistory = () => import('../../components/statusHistory/statusHistory')
export default {
  name: 'callCenter',
  data () {
    return {
      transProps: {
        // Transition name
        name: 'flip-list'
      },
      selectMode: 'multi',
      currentPage: 1,
      per_page: 10,
      total: 6,
      editFlag: false,
      loadingOpenAdd: false,
      loadTable: true,
      switchButton: [],
      showPhone: '',
      deleteId: '',
      selected: [],
      selectedRow: [],
      allStatus: [],
      selectedStatus: '',
      history: [],
      historyId: '',
      columns: [
        { label: 'Selected', key: 'Selected', class: 'text-left', sortable: true },
        { label: 'Name', key: 'name', class: 'text-left', sortable: true },
        { label: 'Phone', key: 'phone', class: 'text-left', sortable: true },
        { label: 'Category', key: 'category', class: 'text-left', sortable: true },
        { label: 'Project', key: 'project', class: 'text-left', sortable: true },
        { label: 'Call Action', key: 'call_action', class: 'text-left', sortable: true },
        { label: 'Last Action', key: 'call_status', class: 'text-left', sortable: true },
        { label: 'Comment', key: 'comment', class: 'text-left', sortable: true },
        { label: 'History', key: 'actions', class: 'text-left' }
      ],
      titleCall: '',
      allFeatures: {},
      getId: '',
      comment: ''
    }
  },
  computed: {
    ...mapGetters(['getUserCallData', 'getNewUserCallData'])
  },
  methods: {
    saveStatus (userId) {
      this.$store.dispatch('changeStatusCall', { status: this.getId, leads_ids: [userId], comment: this.comment }).then(() => {
        this.$bvModal.hide('addStatusAndComments')
        this.getId = ''
        this.comment = ''
        core.showSnackbar('success', 'call Save Succesfully.')
        this.selectedRow = []
      }).catch(err => {
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(err.response.data.errors)) {
          core.showSnackbar('error', value)
        }
      })
    },
    showHistory (id) {
      this.historyId = id
      this.$store.dispatch('gitHistory', { id: id, pageNumber: 1 }).then(res => {
        this.history = res.data.data.data
        this.$bvModal.show('statusHistory')
      })
    },
    endCall () {
      console.log('helo')
      this.$bvModal.hide('addStatusAndComments')
    },
    isSelected (i, id) {
      console.log('id', id)
      this.statusId = id
      return i === this.selectedStatus
    },
    getNewUser (type) {
      this.$store.dispatch('getNewUserCallData', type).then(data => {
        this.titleCall = this.getNewUserCallData && this.getNewUserCallData.data ? this.getNewUserCallData.data.name + ' ' + this.getNewUserCallData.data.phone : ''
        this.$store.dispatch('getSalesCreate').then(data => {
          console.log(data)
          this.allStatus = data
          this.$bvModal.show('addStatusAndComments')
        })
      }).catch(err => {
        core.showSnackbar('error', err.response.data.error)
      })
    },
    openChangeStatus () {
      this.$store.dispatch('getSalesCreate').then(data => {
        this.allStatus = data
        this.$bvModal.show('changeStatus')
      })
    },
    saveEditStatus () {
      this.$store.dispatch('changeStatusCall', { status: this.getId, leads_ids: this.selectedRow, comment: this.comment }).then(() => {
        this.$bvModal.hide('changeStatus')
        core.showSnackbar('success', 'change Status has changed successfully.')
        this.selectedRow = []
      }).catch(err => {
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(err.response.data.errors)) {
          core.showSnackbar('error', value)
        }
      })
    },
    selectAllRows () {
      this.$refs.selectableTable.selectAllRows()
    },
    changeContent () {
      this.loadTable = true
      this.$store.dispatch('getUserCallData', { pageNumber: this.currentPage }).then((data) => {
        this.selectedRow = []
        // eslint-disable-next-line no-undef
        this.loadTable = false
      })
    },
    onRowSelected (items) {
      this.selected = items
    },
    print (id) {
      this.getId = id
    }
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    addLeadModel,
    statusHistory
  },
  mounted () {
    core.index()
  },
  created () {
    this.loadTable = true
    this.$store.dispatch('getUserCallData', { pageNumber: this.currentPage }).then(data => {
      this.currentPage = data.data.meta.currentPage
      this.per_page = data.data.meta.per_page
      this.total = data.data.meta.total
      this.loadTable = false
    })
  }
}
</script>
<style scoped>
*{
  box-sizing: border-box !important;
}
.colorSpan{
  width: 20px;
  height: 20px;
  white-space: nowrap;
}
.table.b-table > tbody > .table-active, .table.b-table > tbody > .table-active > th, .table.b-table > tbody > .table-active > td {
  background-color: rgb(246 33 33 / 5%) !important;
}
.notAllowed {
  pointer-events: auto! important;
  cursor: not-allowed! important;
}
#addStatusAndComments___BV_modal_outer_ #addStatusAndComments .modal-dialog.modal-md.modal-dialog-centered  .modal-header .close {
  display:none !important;
}
.activeSelected{
  background-color: blue !important;
  color:#fff;
  position: relative;
}
.showSelected{
  display: block;
}
</style>
